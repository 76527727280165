import React, { useState } from "react";
import {useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import InputList from "../../../components/InputList";
import { errorMessageNodify, successMessageNodify } from "../../../helpers/notify";
import { Apipost } from "../../../helpers/apiCall";
import { useDispatch } from "react-redux";
import { fetchVenues } from "../../../redux/api";
import '../../../assets/css/payment.css';
import { Formik, useFormik } from "formik";
import * as Yup from "yup";


const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter name").max(30, "Name must be at most 30 characters"),
    address: Yup.string().required("Please Enter address"),
    state: Yup.object().required("Please Enter state Name"),
    country: Yup.object().required("Please Enter country "),
    city: Yup.object().required("Please Enter City"),
    zipcode: Yup.string().max(8, "Invalid Zipcode ").required("Please Enter zipcode"),
    order: Yup.number(),
});


const AddVenue = ({setRefresh, closeModal }) => {
    const [inputList, setInputList] = useState([{
        fieldname: "",
    }]);
    const dispatch = useDispatch()
    const [image, setImage] = useState("");

    const { sportId } = useParams();

    const formik = useFormik({
        initialValues: {
            id: 0,
            name: "",
            address: "",
            state: null,
            city: null,
            country: null,
            zipcode: "",
            order: "0",
            isSubmitting:false
        },
        validationSchema: validationSchema,
        onSubmit: (values) =>{ handleSubmit(values)},
    });



    const handleSubmit = () => {
            formik.setFieldValue('isSubmitting',true)
        let temp = {
            // ...values,
            name: formik.values.name,
            state: JSON.stringify(formik.values.state),
            country: JSON.stringify(formik.values.country),
            city: JSON.stringify(formik.values.city),
            // order: formik.values.order,
            zipcode:formik.values.zipcode,
            address:formik.values.address,
            image,
            sportId: sportId,
            fieldlist: JSON.stringify(inputList)
        }
        Apipost('/venue/add', temp, "multipart/form-data", true).then(
            (success) => {
                closeModal()
                successMessageNodify(success.data.message);
                dispatch(fetchVenues())
                closeModal()
                formik.setFieldValue('isSubmitting',false)
                setRefresh(prev => !prev)
                return;
            },
            (err) => {
                console.log(err)
                errorMessageNodify(err.response.data.message);
                formik.setFieldValue('isSubmitting',false)
                return;
            }

        )
    };

    const hiddenFileInput = React.useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImage(event.target.files[0]);
        }
    };
    
    return (
        <>
            <Formik>
                <div className="dashboard-auto-wrapper season-popup px-2">
                    <div className="create-from">
                        <h4>Create Venue</h4>
                        <div className="d-flex row justify-content-between align-items-center">
                            <div className="profile-img-box col-md-2">
                                <div className="rounded-circle">
                                    <img alt="" src={image ? URL.createObjectURL(image) : require("../../../assets/images/usainvitationallogo.png")} onClick={handleClick} className="venue-profile" />
                                </div>

                            </div>
                            <input
                                type="file"
                                ref={hiddenFileInput}
                                onChange={onImageChange}
                                accept="image/*"
                                style={{ display: "none" }}
                            />
                            <div className="col-md-10">
                                <div className="form-group mt-4 mb-4">
                                    <div className="d-flex">
                                        <label className="form-label">Name</label><span className="imp_ast">*</span>
                                    </div>
                                    <div className="input-right-icon">
                                        <input
                                            className={`form-control ${formik.touched.name && formik.errors.name ? "is-invalid" : ""
                                                }`}
                                            placeholder="Enter Name"
                                            name="name"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.touched.name && formik.errors.name && (
                                            <div className="invalid-feedback">{formik.errors.name}</div>
                                        )}
                                        {/* <input className="form-control" name='name' onChange={(e) => onTextChange(e, setValues)} /> */}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="create-form-inner add-ven-form">
                            <div className="row">

                                <div className="col-md-6">
                                    <div className="form-group mt-4 mb-4">
                                        <div className="d-flex">
                                            <label className="form-label">Address</label><span className="imp_ast">*</span>
                                        </div>
                                        <div className="input-right-icon">
                                            {/* <input className="form-control" name="address" onChange={(e) => onTextChange(e, setValues)} /> */}
                                            <input
                                            className={`form-control ${formik.touched.address && formik.errors.address ? "is-invalid" : ""
                                                }`}
                                            placeholder="Enter Address"
                                            name="address"
                                            value={formik.values.address}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.touched.address && formik.errors.address && (
                                            <div className="invalid-feedback">{formik.errors.address}</div>
                                        )}
                                        </div>
                                        <span className="error name-error">This field is required*</span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mt-4 mb-4">
                                        <div className="d-flex">
                                            <label className="form-label">Country</label><span className="imp_ast">*</span>
                                        </div>
                                        <div className="input-right-icon">
                                            <Select
                                                options={Country.getAllCountries()}
                                                getOptionLabel={(options) => {
                                                    return options["name"];
                                                }}
                                                getOptionValue={(options) => {
                                                    return options["name"];
                                                }}
                                                value={formik.values.country}
                                                onChange={(item) => {
                                                    // Set the selected country
                                                    formik.setFieldValue("country", item);
                                                    // Clear the state and city fields
                                                    formik.setFieldValue("state", null);
                                                    formik.setFieldValue("city", null);
                                                }}
                                                
                                                className={`formm-control ${formik.touched.country && formik.errors.country  ? "is-invalid" : ""
                                                    }`}
                                            />
                                            {formik.touched.country && formik.errors.country && (
                                                <div className="invalid-feedback">{formik.errors.country}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">


                                <div className="col-md-6">
                                    <div className="form-group mt-4 mb-4">
                                        <div className="d-flex">
                                            <label className="form-label">State</label><span className="imp_ast">*</span>
                                        </div>
                                        <div className="input-right-icon">
                                            <Select
                                                options={State?.getStatesOfCountry(formik.values.country?.isoCode)}
                                                getOptionLabel={(options) => {
                                                    return options["name"];
                                                }}
                                                getOptionValue={(options) => {
                                                    return options["name"];
                                                }}
                                                value={formik.values.state}                                             
                                                onChange={(item) => {
                                                    // Set the selected country
                                                    // Clear the state and city fields
                                                    formik.setFieldValue("state", item);
                                                    formik.setFieldValue("city", null);
                                                }}
                                                className={`formm-control ${formik.touched.state && formik.errors.state ? "is-invalid" : ""
                                                    }`}
                                            />
                                            {formik.touched.state && formik.errors.state && (
                                                <div className="invalid-feedback">{formik.errors.state}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mt-4 mb-4">
                                        <div className="d-flex"><label className="form-label">City</label><span className="imp_ast">*</span></div>
                                        <div className="input-right-icon">
                                            <Select
                                                options={City.getCitiesOfState(
                                                    formik.values.state?.countryCode,
                                                    formik.values.state?.isoCode
                                                )}
                                                getOptionLabel={(options) => {
                                                    return options["name"];
                                                }}
                                                getOptionValue={(options) => {
                                                    return options["name"];
                                                }}
                                                value={formik.values.city}
                                                onChange={(item) => formik.setFieldValue("city", item)}
                                                className={`formm-control ${formik.touched.city && formik.errors.city ? "is-invalid" : ""
                                                    }`}
                                            />
                                            {formik.touched.city && formik.errors.city && (
                                                <div className="invalid-feedback">{formik.errors.city}</div>
                                            )}
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group mt-4 mb-4">
                                        <div className="d-flex">
                                            <label className="form-label">Zipcode</label><span className="imp_ast">*</span>
                                        </div>
                                        <div className="input-right-icon">
                                            {/* <input className="form-control" type='number' min='0' max='999999' name="zipcode" onChange={(e) => onTextChange(e, setValues)} /> */}
                                       
                                            <input
                                            className={`form-control ${formik.touched.zipcode && formik.errors.zipcode ? "is-invalid" : ""
                                                }`}
                                                type='text' 
                                            placeholder="Enter Zipcode"
                                            name="zipcode"
                                            value={formik.values.zipcode}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.touched.zipcode && formik.errors.zipcode && (
                                            <div className="invalid-feedback">{formik.errors.zipcode}</div>
                                        )}
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                            <div className="row">

                                <div className="col-md-12">
                                    <div className="form-group mb-0 add-files mt-5">
                                        <InputList inputList={inputList} setInputList={setInputList} />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="d-flex  align-items-center    ">
                            <div className=" submission">
                                {/* <Link to={`/organizer/${sportId}/venues`}><button className="btn btn-transparent">Back</button></Link> */}
                                <button className="btn btn-dark savesubmit payment-btn" type="submit" disabled={formik.values.isSubmitting} onClick={formik.handleSubmit}  >
                                    <div className="d-flex align-items-center justify-content-center">{

                                        formik.values.isSubmitting ? <div> <FontAwesomeIcon icon={faCircleNotch} spin /></div> : 'Create'
                                    }</div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Formik>
        </>
    );
};

export default AddVenue;
