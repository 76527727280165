import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import { Apipost } from '../../../helpers/apiCall'
import InputList from "../../../components/InputList";
import { errorMessageNodify, successMessageNodify } from "../../../helpers/notify";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { fetchVenues } from "../../../redux/api";
import { useDispatch } from "react-redux";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter name").max(30, "Name must be at most 30 characters"),
    address: Yup.string().required("Please Enter address"),
    state: Yup.object().required("Please Enter state Name"),
    country: Yup.object().required("Please Enter country "),
    city: Yup.object().required("Please Enter City"),
    zipcode: Yup.string().max(8, "Invalid Zipcode ").required("Please Enter zipcode"),
    order: Yup.number(),
});
const EditVenue = ({ value, closeModal, setRefresh }) => {
    const [inputList, setInputList] = useState([]);
    const [image, setImage] = useState("");
    const [imageFile, setImageFile] = useState('')
    const [loading1, setLoading1] = useState(false);
    const dispatch = useDispatch()
    const stringify = (value) => {
        return JSON.stringify(value)
    }
    useEffect(() => {
         setInputList(value.id.fields?.length ? value.id.fields :[{
        fieldname: "",
    }]) }, [])

    const formik = useFormik({
        initialValues: {
            id: value.id.id,
            name: value.id.name,
            address: value.id.address,
            state: value.id.state_id||{},
            city: value.id.city||{},
            country: value.id.country_id,
            zipcode: value.id.pincode,
            order: value.id.order,

        },
        validationSchema: validationSchema,
        onSubmit: (values) => handleSubmit(values),
    });
    const handleSubmit = () => {
        setLoading1(true);
        let values = {
            name: formik.values.name, order: formik.values.order, pincode: formik.values.zipcode, address: formik.values.address,
            state: stringify(formik.values.state), city: stringify(formik.values.city), country: stringify(formik.values.country),
            id: formik.values.id, image: value.venue_Image
        }
            if (imageFile)values = { ...values, image: imageFile }
            else values = { ...values, venue_image: value.id.venue_Image }
        if (inputList) values = { ...values, fieldlist: JSON.stringify(inputList) }


        // if (objectValuesNonEmpty(values)) {
        formik.setSubmitting(true);
        Apipost('/venue/update', values, "multipart/form-data", true).then(
            (success) => {
                successMessageNodify(success?.data.message);
                setLoading1(false);
                setRefresh(prev => !prev)
                dispatch(fetchVenues())
                formik.setSubmitting(false);
                closeModal()
            },
            (err) => {
                formik.setSubmitting(false);
                errorMessageNodify(err.response?.data.message);
                setLoading1(false);
            }

        );

    };

    const hiddenFileInput = React.useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImageFile(event.target.files[0])
            setImage(URL.createObjectURL(event.target.files[0]));
        }
    };

    return (
        <>
            <Formik>
                <div className="dashboard-auto-wrapper px-2 season-popup">
                    <div className="create-from ven-form">
                        <h4>Update Venue</h4>
                        <div className="d-flex justify-content-between align-items-center row">
                            <div className="profile-img-box col-md-2 ">
                                <div className="updatte-profile-image">
                                    <img alt="" className="rounded-circle veneu-profile-user" src={(image || value.id.venue_Image) ? (image || value.id.venue_Image) : require("../../../assets/images/usainvitationallogo.png")} onClick={handleClick} />
                                </div>

                            </div>
                            <input type="file" ref={hiddenFileInput} onChange={onImageChange} accept="image/*" style={{ display: "none" }} />
                            <div className="col-md-10 ">
                                <div className="form-group mb-4">
                                    <label className="form-label">Name</label>
                                    <div className="input-right-icon">
                                        {/* <input className="form-control" name='name' value={value.name} onChange={(e) => onTextChange(e, setValue)} /> */}
                                        <input
                                            className={`form-control ${formik.touched.name && formik.errors.name ? "is-invalid" : ""
                                                }`}
                                            placeholder="Enter Name"
                                            name="name"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.touched.name && formik.errors.name && (
                                            <div className="invalid-feedback">{formik.errors.name}</div>
                                        )}
                                    </div>
                                    {/* <span className="error name-error">This field is required*</span> */}
                                </div>
                            </div>
                        </div>
                        <div className="create-form-inner addd-ven">
                            <div className="row">

                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Address</label>
                                        <div className="input-right-icon">
                                            <input
                                                className={`form-control ${formik.touched.address && formik.errors.address ? "is-invalid" : ""
                                                    }`}
                                                placeholder="Enter Address"
                                                name="address"
                                                value={formik.values.address}
                                                onChange={formik.handleChange}
                                            />
                                            {formik.touched.address && formik.errors.address && (
                                                <div className="invalid-feedback">{formik.errors.address}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Country</label>
                                        <div className="input-right-icon">
                                            <Select
                                                options={Country.getAllCountries()}
                                                getOptionLabel={(options) => {
                                                    return options["name"];
                                                }}
                                                getOptionValue={(options) => {
                                                    return options["name"];
                                                }}
                                                value={formik.values.country}
                                                onChange={(item) => {
                                                    // Set the selected country
                                                    formik.setFieldValue("country", item);
                                                    // Clear the state and city fields
                                                    formik.setFieldValue("state", null);
                                                    formik.setFieldValue("city", null);
                                                }}
                                              
                                            />
                                            {formik.touched.country && formik.errors.country && (
                                                <div className="invalid-feedback">{formik.errors.country}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">


                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label className="form-label">State</label>
                                        <div className="input-right-icon">
                                            <Select
                                                options={State?.getStatesOfCountry(formik.values.country?.isoCode)}
                                                getOptionLabel={(options) => {
                                                    return options["name"];
                                                }}
                                                getOptionValue={(options) => {
                                                    return options["name"];
                                                }}
                                                value={formik.values.state}
                                                onChange={(item) => {
                                                    // Set the selected country
                                                 
                                                    // Clear the state and city fields
                                                    formik.setFieldValue("state", item);
                                                    formik.setFieldValue("city", null);
                                                }}
                                                className={`formm-control ${formik.touched.state && formik.errors.state ? "is-invalid" : ""
                                                    }`}
                                            />
                                            {formik.touched.state && formik.errors.state && (
                                                <div className="invalid-feedback">{formik.errors.state}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label className="form-label">City</label>
                                        <div className="input-right-icon">
                                            <Select
                                                options={City.getCitiesOfState(
                                                    formik.values.state?.countryCode,
                                                    formik.values.state?.isoCode
                                                )}
                                                getOptionLabel={(options) => {
                                                    return options["name"];
                                                }}
                                                getOptionValue={(options) => {
                                                    return options["name"];
                                                }}
                                                value={formik.values.city}
                                                onChange={(item) => formik.setFieldValue("city", item)}
                                                className={`formm-control ${formik.touched.city && formik.errors.city ? "is-invalid" : ""
                                                    }`}
                                            />
                                            {formik.touched.city && formik.errors.city && (
                                                <div className="invalid-feedback">{formik.errors.city}</div>
                                            )}
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Zipcode</label>
                                        <div className="input-right-icon">
                                            <input
                                                className={`form-control ${formik.touched.zipcode && formik.errors.zipcode ? "is-invalid" : ""
                                                    }`}
                                                type='text'
                                                placeholder="Enter Zipcode"
                                                name="zipcode"
                                                value={formik.values.zipcode}
                                                onChange={formik.handleChange}
                                            />
                                            {formik.touched.zipcode && formik.errors.zipcode && (
                                                <div className="invalid-feedback">{formik.errors.zipcode}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                

                            </div>
                            <div className="row">

                                <div className="col-md-12">
                                    <div className="form-group mb-4 text-end edit-venue-field">
                                        <InputList inputList={inputList} setInputList={setInputList} title='Add Fields' />
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                            </div>
                        </div>
                        <div className="d-flex  align-items-center divisions-schedule">
                            <div className="create-from-btn submission">

                                <button className="btn btn-dark savesubmit" onClick={formik.handleSubmit}
                                    disabled={formik.isSubmitting}>
                                    <div className="d-flex align-items-center justify-content-center">

                                        {

                                            formik.isSubmitting ? <div> <FontAwesomeIcon icon={faCircleNotch} spin /></div> : 'Update'
                                        }
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Formik>
        </>
    );
};

export default EditVenue;
