import { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Apipost } from '../../helpers/apiCall';
import { useDispatch } from 'react-redux';
import { fetchPaymentDetail } from '../../redux/api'
import { Button } from 'react-bootstrap';

function Completion() {

  const [paymentIntent, setPaymentIntent] = useState({paymentIntent: {id:''} })
  const dispatch = useDispatch()
  useEffect(() => {
    const retrievePaymentIntent = async () => {
      // Load Stripe asynchronously
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

      // Retrieve client secret from URL
      const url = new URL(window.location);
      const clientSecret = url.searchParams.get('payment_intent_client_secret');

      // Use Stripe to retrieve payment intent
      const { error, paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
    
      if(paymentIntent?.id?.length >0  ){
        setPaymentIntent({paymentIntent })
      }
    
      Apipost("/payment/update-payment", { client_secret: clientSecret, paymentIntent: paymentIntent }, "application/x-www-form-urlencoded", true).then((success) => {
        dispatch(fetchPaymentDetail())
      }, (err) => {

      })
    };

    retrievePaymentIntent();
  }, []);

  return (
    <>

      <div className='payment-divison-successful'>
        <div className='Sucessful'>
          <span>
            <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M27.6051 0C29.2441 0 30.8849 0 32.5239 0C33.4871 0.139959 34.4594 0.224671 35.4097 0.425402C47.2878 2.93546 55.0905 10.0016 58.8933 21.5058C59.5379 23.4542 59.816 25.4855 60.0462 27.5204V32.4392C59.9743 33.0377 59.9173 33.6399 59.827 34.2347C58.8215 40.8312 56.0334 46.5659 51.2692 51.2361C44.6562 57.7184 36.6748 60.6097 27.4191 59.8933C23.1909 59.5673 19.2333 58.3298 15.5373 56.2765C4.47686 50.1367 -1.97599 37.6195 0.543277 24.6567C2.85997 12.7344 10.0808 4.9575 21.5905 1.15282C23.5389 0.508273 25.572 0.232037 27.607 0H27.6051ZM47.6045 20.0547C47.6101 18.3144 46.3228 17.0327 44.6838 17.0106C43.6801 16.9977 42.9546 17.4986 42.2861 18.1689C36.8387 23.6236 31.3803 29.0691 25.944 34.5349C25.526 34.9548 25.2737 34.9566 24.8556 34.557C23.6991 33.4521 22.504 32.3858 21.3235 31.3048C19.9847 30.0802 18.6625 28.8353 17.2979 27.6382C15.5962 26.1447 13.0641 26.9127 12.5908 29.0599C12.3219 30.2772 12.7584 31.2698 13.67 32.0967C16.8946 35.0211 20.0989 37.9658 23.329 40.8828C24.9164 42.3155 26.3731 42.2529 27.9034 40.741C29.8334 38.835 31.7615 36.9253 33.6878 35.0156C37.9142 30.8242 42.1388 26.6309 46.367 22.4395C47.0631 21.7508 47.6543 21.0141 47.6027 20.0528L47.6045 20.0547Z" fill="#27AE60" />
            </svg>
          </span>
          <h3 className='payment-color'> Payment Sucessful !</h3>
        </div>
        <h5>Thank you ! Your Payment has been recevied</h5>
        <div className='transaction'>
          <div className='d-flex'>  <span>Transaction ID :</span> <p className='ms-2'>{paymentIntent?.paymentIntent?.id}</p></div>
          {/* <div className='d-flex'>  <span> Payment Method : </span> <p className='ms-2'>EFT(Check) XXX{paymentIntent?.paymentMethod?.card?.last4}</p></div> */}

        </div>
        <h6>  Total Payment Amount</h6>
        <h3 className='total-amount'>${paymentIntent?.paymentIntent?.amount /100}</h3>
        <p className='receipt-details'>A Receipt with details for this transaction has
          been sent via emails for your records. Click below to download and view details</p>

        <div className='payment-btns d-flex'>
          <Button variant="primary-download-btn">Download Receipt</Button>{' '}
          <Button href="#" variant="secondary" className=" ms-2" >
            View Details
          </Button>
        </div>

      </div>
    </>
  );
}

export default Completion;
