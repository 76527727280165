import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { ApiGetAuth, Apipost } from "../../helpers//apiCall";
import Offcanvas from 'react-bootstrap/Offcanvas';
import sgraphic from '../../assets/images/search-graphic.png';
import user from '../../assets/images/no-profile.png';

import { faCircleNotch, faSearch } from "@fortawesome/free-solid-svg-icons";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Scrollbar from "react-custom-scrollbars";
import {Form} from 'react-bootstrap';
import { onTextChange } from '../../helpers/commonFunction';
import { errorMessageNodify, successMessageNodify } from '../../helpers/notify';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

export const EnrollSideNav = ({enrollmentList,setValues,handleSubmit,show , setShow,loading,coachDetail,teamList,AddedTeams,handleTeamList ,Enrollloading,seleTeamList,handleEnrollTeam,isChecked1,isChecked2,handleChecked1,handleChecked2 ,...props}) => {

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);   
     return (
        <>
            <div className="Enrollsidenav">
                <Button variant="primary" onClick={handleShow} className="btn btn-success w-100">
                    Enroll Team
                </Button>
              
                <Offcanvas show={show} onHide={handleClose} {...props} className="sidenav_offcanvas h-100">
                    <div className="create_game enrollSlideNav px-3">
                        <div className="create-match-wrap season-popup">
                            <Offcanvas.Body>
                                <Offcanvas.Header closeButton className="offcanvas-header">
                                    {/* <h4 className='text-left'>Enroll Team</h4> */}
                                </Offcanvas.Header>
                                <div className="custom-pop-box">
                                    <div className="row">
                                        <div className="col-xxl-12 col-md-12 col-xs-12">
                                            <div className="enroll_team_button_search  enrollment-button">
                                            <h5 className='text-left'><b>Enroll Team</b></h5>
                                                <div className="search ">
                                                    <input type="text" className="form-control" placeholder=" Enter Coach/Team Mom Email" name="email"
                                                        onChange={(e) => onTextChange(e, setValues)} />
                                                    <span className='input-icon-alt' ><FontAwesomeIcon icon={faSearch} /></span>
                                                </div>
                                                
                                                <button className="btn btn-dark green white-orange-color find-btn" onClick={handleSubmit}> {loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : ' Find'}</button>
                                            </div>
                                            <div className="card">
                                                 <div  className={`card-header `}>
                                                   <span className="text-danger">Note:</span> We can enroll teams created by Team Moms/Coaches who have already set up their teams through the mobile app.
                                                 </div>
                                               </div>
                                            <div className="enroll_team_sidnav_content">
                                                {coachDetail?.email?.length >0 ?<>
                                                    <div className="profile-details">
                                                    <div className="details">
                                                    <img className="img-fluid" src={coachDetail.profile_pic || user} alt="Profile-Pic"/>
                                                        {/* <img className="img-fluid" src={user} alt="Profile-Pic"/> */}
                                                    </div>
                                                    <div className="enroll_user_detail_enrollment">
                                                        <h6 className="coach_enrollment">Coach's info</h6>
                                                        <h6>{coachDetail.firstname}</h6>
                                                        <div className="coach-info">
                                                            <span className="coach-mail"> <span><b>Gmail ID:</b></span> {coachDetail.email}</span>
                                                        </div>
                                                        <div className="coach-info">
                                                            <span className="coach-mail"> <span><b>Contact:</b></span> {coachDetail.contact_number}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                </>:<></>}
                                            </div>
                                        </div>
                                        <div className="col-xxl-12 col-md-12 col-xs-12">
                                        <div className="table-format top-table enroll-tab">
                                        <Scrollbar
                                            // style={{ height: "50vh" }}
                                            className="table-scroll"
                                            autoHide
                                            removeTracksWhenNotUsed

                                        >

                                            <div className="table-wrapper enrollment_table_team_player">
                                                {teamList?.length > 0 ? <table className="table new-table table-striped ">
                                                    <thead>
                                                        <tr>
                                                            <th ></th>
                                                            <th>TEAM </th>
                                                            {/* <th>Team Code</th> */}
                                                            <th>PLAYER</th>
                                                            {/* <th>Verified Players</th> */}
                                                            {/* <th>Coach</th> */}
                                                            {/* <th>Team Mom</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            teamList?.map((team, i) => {

                                                                return (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <span className="custom-checkbox">
                                                                            {(!!AddedTeams?.filter((item => item.id == team.id))?.length || !!enrollmentList.filter(item=>item.team_id == team.id).length   ) ? <><input type="checkbox" value={team.id} onChange={handleTeamList} checked={true} /></> : <>
                                                                                <input type="checkbox" value={team.id} onChange={handleTeamList} /></>
                                                                            }
                                                                            {/* <label for="selectAll"></label> */}
                                                                        </span>
                                                                    </td>

                                                                    <td>   
                                                                        <div className="coach-profle">
                                                                            <div>
                                                                                {/* <img src={team.team_logo} width="60px" /> */}
                                                                            </div>
                                                                            <div>
                                                                                <h5>{team.team_name}</h5>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    {/* <td className="d-flex flex-column ">
                                                                        <QRCode
                                                                            size={256}
                                                                            style={{ height: "auto", maxWidth: "15%", width: "15%" }}
                                                                            value={team?.team_code}
                                                                            viewBox={`0 0 256 256`}
                                                                        />
                                                                    </td> */}
                                                                    {/* <td>
                                                                        <p className="mail  text-center" >{team.members?.filter((item => item.role_id == 4))?.length || 0}</p>
                                                                    </td> */}
                                                                    <td>
                                                                        <div className="player_verified_player">
                                                                            <p className="mail  text-left" >Player: <span className="blue_text">{team.total_ver_player || 0}</span></p>
                                                                            <p className="mail  text-center" >Verified Player: <span className="verified_player">{team.members?.filter((item => item.role_id == 3))?.length + 1 || 1}</span></p>
                                                                        </div>    
                                                                    </td>
                                                                    {/* <td>
                                                                        <p className="mail  text-center" >{team.members?.filter((item => item.role_id == 3))?.length + 1 || 1}</p>
                                                                    </td> */}
                                                                    {/* <td className="select-dropdown">
                                                                        <p className="mail  text-center" >{team.members?.filter((item => item.role_id == 9))?.length || 0}</p>
                                                                    </td> */}

                                                                </tr>)
                                                            })}

                                                            

                                                    </tbody>
                                                </table> : <><div className="enro">
                                                    <img src={sgraphic} />
                                                    <p>Please fill the email ID to above input to search the Coach</p>
                                                </div></>}
                                            </div>

                                        </Scrollbar>
                                        <div className="col-sm-12">
                                    {teamList?.length > 0 ?
                                        <>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="checkbox">
                                                        <div className="mt-5">
                                                            <label className="custom-check">I agree to the GIU TeamConnect Terms of Service.
                                                                <input type="checkbox" checked={isChecked1} onChange={handleChecked1} /><span className="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {/* <div className="checkbox">
                                                        <div className="mb-3 mt-1">
                                                            <label className="custom-check">I confirmed that, all the players documents are verfied througly
                                                                <input type="checkbox" checked={isChecked2} onChange={handleChecked2} /><span className="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div> */}

                                                </div>
                                                <div className="col-sm-12">
                                                    <div className=" dashboard-top-btn  mt-1 ">
                                                        {/* <PaymentPopup  handlesubmit ={handleEnrollTeam} Content ={  Enrollloading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : ' Pay And Enroll  Team'} type="Teams" formik={coachDetail} teamList={teamList} seleTeamList={seleTeamList} price ={price} disabled={(isChecked1 && isChecked2 && seleTeamList.length) ? false : true}/> */}
                                                        <button
                                                            disabled={(isChecked1  && seleTeamList?.length) ? false : true}
                                                            className="btn btn-dark savesubmit ellorment_green_button"
                                                            onClick={handleEnrollTeam}
                                                        >
                                                            {Enrollloading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Enroll  Team'}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }
                                </div>
                                    </div>
                                        </div>
                                    </div>
                                </div>
                            </Offcanvas.Body>
                        </div>
                    </div>
                </Offcanvas>
            </div>
        </>
    )
}

export const ApproveButton = ({team_id,email,enrollment_id,setRefresh,...props}) => {
    const {groupId} = useParams()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [text,setText] = useState('')

    const Approve = (event) => {
        event.preventDefault();
        Apipost(`/games/verify-Enroll`,{groupid:groupId ,team_id:team_id,text:text,email:email,enrollment_id:enrollment_id},"application/x-www-form-urlencoded", true).then(
            (reponse) => {
                if (reponse.data.status === true) {
                    handleClose()
                    successMessageNodify(reponse.msg);
                    setRefresh(prev=>!prev)
                    return;
                } else {
                    errorMessageNodify(reponse.msg);
                    return;
                }
            },
            (err) => {
                errorMessageNodify(err.msg);
                return;
            }
        );
    }
    

    return(
        <>
            <div className="approve_button_table_listing">
                <Button variant="primary" onClick={handleShow} className="btn btn-success w-100">
                    Approve
                </Button>
            </div>    

            <Offcanvas show={show} onHide={handleClose} {...props} className="sidenav_offcanvas approve_button-details">
                <div className="create_game px-3">
                    <div className="create-match-wrap season-popup approve_button_table_listing_content">
                        <Offcanvas.Body>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                Ready to Approve?
                                <p>Please review the details carefully before approving. This action cannot be undone.</p>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                            <Form>
                                
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" placeholder="Remarks Optional">
                                    <Form.Control as="textarea" rows={3} value={text} onChange={(e)=>setText(e.target.value)} placeholder='Remarks (Optional)'/>
                                </Form.Group>
                            </Form>
                            <div className="approve_button_table_listing_button">
                                <button className="btn  btn-success mx-2" onClick={Approve}>Approve</button>
                                <button className="btn  btn-success mx-2" onClick={handleClose}>Cancel</button>
                            </div>
                        </Offcanvas.Body>
                    </div>    
                </div>    
            </Offcanvas>
        </>
    )
}

export const ReminderButton = ({team_id,email,setRefresh,enrollment_id,...props}) => {
    const {groupId} = useParams()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [text,setText] = useState('')
    const Reminder = (event ) => {
        event.preventDefault();
        Apipost(`/games/reminder-Enroll`, {groupid:groupId ,team_id:team_id,text:text,email:email,enrollment_id:enrollment_id},"application/x-www-form-urlencoded", true).then(
            (reponse) => {
                if (reponse.data.status === true) {
                    handleClose()
                    successMessageNodify(reponse.msg);
                    setRefresh(prev=>!prev)
                    return;
                } else {
                    errorMessageNodify(reponse.msg);
                    return;
                }
            },
            (err) => {
                errorMessageNodify(err.msg);
                return;
            }
        );
    }
    return(
        <>
            <div className="approve_button_table_listing blue">
                <Button variant="primary" onClick={handleShow} className="btn btn-success w-100">
                    Reminder
                </Button>
            </div>    

            <Offcanvas show={show} onHide={handleClose} {...props} className="sidenav_offcanvas approve_button-details">
                <div className="create_game px-3">
                    <div className="create-match-wrap season-popup approve_button_table_listing_content">
                        <Offcanvas.Body>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                Set Incomplete Information Reminder
                                <p>Please provide the details to need to fill and set it as a reminder.</p>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                            <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" placeholder="Remarks Optional">
                                    <Form.Control as="textarea" rows={3} value={text} onChange={(e)=>setText(e.target.value)} placeholder="Remarks Optional" />
                                </Form.Group>
                            </Form>
                            <div className="approve_button_table_listing_button blue">
                                <button className="btn  btn-success mx-2" onClick={Reminder}>Reminder</button>
                                <button className="btn  btn-success mx-2" onClick={handleClose}>Cancel</button>
                            </div>
                        </Offcanvas.Body>
                    </div>    
                </div>    
            </Offcanvas>
        </>
    )
}

export const RejectButton = ({team_id,email,enrollment_id,setRefresh,...props}) => {
    const {groupId} = useParams()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [text,setText] = useState('')
    const Reject = (event) => {
        event.preventDefault();
        Apipost(`/games/reject-Enroll`, {groupid:groupId ,team_id:team_id, text:text,email:email,enrollment_id:enrollment_id} ,"application/x-www-form-urlencoded", true).then(
            (reponse) => {
                if (reponse.data.status === true) {
                    handleClose()
                    successMessageNodify(reponse.msg);
                    setRefresh(prev=>!prev)
                    return;
                } else {
                    errorMessageNodify(reponse.msg);
                    return;
                }
            },
            (err) => {
                errorMessageNodify(err.msg);
                return;
            }
        );
    }
    return(
        <>
            <div className="approve_button_table_listing red">
                <Button variant="primary" onClick={handleShow} className="btn btn-success w-100">
                Reject
                </Button>
            </div>    

            <Offcanvas show={show} onHide={handleClose} {...props} className="sidenav_offcanvas approve_button-details">
                <div className="create_game px-3">
                    <div className="create-match-wrap season-popup approve_button_table_listing_content">
                        <Offcanvas.Body>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                            Are You sure to Reject ?
                                <p>Please review the details carefully before Reject. This action cannot be undone.</p>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                            <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" placeholder="Remarks Optional">
                                    <Form.Control as="textarea" rows={3} value={text} onChange={(e)=>setText(e.target.value)} placeholder="Remarks Optional" />
                                </Form.Group>
                            </Form>
                            <div className="approve_button_table_listing_button red">
                                <button className="btn  btn-success mx-2" onClick={Reject}>Reject</button>
                                <button className="btn  btn-success mx-2" onClick={handleClose}>Cancel</button>
                            </div>
                        </Offcanvas.Body>
                    </div>    
                </div>    
            </Offcanvas>
        </>
    )
}