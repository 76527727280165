import React, { useEffect, useState } from 'react';
import PayemntDetailLogo from '../../../assets/images/payment_detail_logo.png';
import { NavLink, useParams } from 'react-router-dom';
import { Col, Row, Table, Image } from 'react-bootstrap';
import PlayModal from '../../../components/PlayVideoModal/playVideoModal';
import { ApiGetAuth } from '../../../helpers/apiCall';
import moment from 'moment/moment';
import { usePDF } from 'react-to-pdf';
import { TournamentLeagueIcon } from '../../Leagues/Leagues';

const PaymentHistoryDetail = () => {
const {id} = useParams();
const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});
const  [transactionDetails , setTransactionDetails ] = useState(null)
const [paymentInfo ,setPaymentInfo] = useState({})
const [transaction, setTransaction] =useState('')

    useEffect(()=>{
        ApiGetAuth(`payment/payment-details/${id}`).then((data) => {
			if (data?.data) {
                setTransactionDetails(data.data.rows)
                setPaymentInfo(data.data.paymentInfo)
                if(data.data.rows?.length){
                     const {stripe_transaction_id,payment_status,transaction_date,total_amount,order_id,discount_amount,team_charge_paid,transaction_charge_paid,tax_paid,teams_count,coupon_code,coupon_description,tax_amount_paid} = data.data.rows[0]
                     setTransaction({order_id,stripe_transaction_id,payment_status,transaction_date: moment(transaction_date).format('MM/DD/YYYY'),total_amount,team_charge_paid,transaction_charge_paid,tax_paid,teams_count,tax_amount_paid,coupon_code,discount_amount,coupon_description})
                }
			}
		  }, (err) => {
			console.log(err);
		  })
    },[id])


    return (
        <span >
            <nav aria-label="breadcrumb" className="breadcrumb_dashboard">
                <ol className="breadcrumb">
                <li className="breadcrumb-item">
                        <NavLink to="#" role="button" tabIndex="0"> Home</NavLink>
                    </li>

                <li className="breadcrumb-item active" aria-current="page">
                        <NavLink to="/organizer/dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                        <NavLink to="/organizer/all/payment-history" role="button" tabIndex="0">Payments</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                        <NavLink to="#" role="button" tabIndex="0"> Payment History</NavLink>
                    </li>
                   
                    {/* <PlayModal /> */}
                </ol>
            </nav>

            <div className="dahboard_inner_white_background" ref={targetRef}>
                <div className="row">
                    <div className="col-xxl-12 col-lg-12 col-md-12 col-xs-12">
                        <div className="payment_recepit">
                            <div className="payment_recepit_heading">
                                <h4>
                                    <span>Invoice ID : <span>{transaction?.order_id}</span></span>
                                    
                                    <span>Stripe Ref ID : <span>{transaction?.stripe_transaction_id}</span></span>
                                    <span className=""><button className={ transaction?.payment_status !== 'Paid' ? "btn btn-danger" : "btn btn-success" } >{transaction?.payment_status}</button></span>
                                </h4>
                                <p>{transaction?.transaction_date}</p>
                            </div>
                            <button className="btn btn-primary download_receipt" onClick={() => toPDF()}>Download Receipt</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xxl-8 col-lg-12 col-md-12 col-xs-12">
                        <div className="payment_description">
                            <Table >
                                <thead>
                                    <tr>
                                        <th>Game</th>
                                        <th>No. of Teams</th>
                                        <th>Date</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {transactionDetails == null ? <>Loading ..</>: <>
                                    {transactionDetails.map(item=>( <tr>
                                        <td>
                                            <div className="game_type_payment_transaction">
                                            <TournamentLeagueIcon game_type={item.game_type} />

                                                <div className="game_team_name_season">
                                                    <h4><b>{item.game_title}</b></h4>
                                                    <p>Season: {item.season_name}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td><span><b>Team:</b></span> {item.teams_count}</td>
                                        <td>{moment(item.transaction_date).format('MM/DD/YYYY')}</td>
                                        <td>$ {(item.teams_count * item.team_charge_paid).toFixed(2)}</td>
                                    </tr>))}
                                </> }
                                   
                                </tbody>
                            </Table>
                            <div className="payment_summary">
                                <h4>Payment summary</h4>
                                <p>
                                    <span>Applied Coupon</span>
                                    <span> {transaction.coupon_code} </span>
                                </p>
                                <p>
                                    <span>Coupon Description</span>
                                    <span> {transaction.coupon_description}</span>
                                </p>
                                <p>
                                    <span>Discount Amount</span>
                                    <span>- $ {Number(transaction?.discount_amount).toFixed(2)}</span>
                                </p>
                                <p>
                                    <span>Transaction {(transaction?.transaction_charge_paid)} % +  Tax {(transaction?.tax_paid)} %</span>
                                    <span>$ {Number(transaction?.tax_amount_paid).toFixed(2)}</span>
                                </p>
                             
                                <p>
                                    <span>Subtotal</span>
                                    <span>$ {(transaction?.total_amount/100).toFixed(2)}</span>
                                </p>
                                <p>
                                    <span><b> Total : </b></span>
                                    <span>Paid $ {(transaction?.total_amount/100).toFixed(2)}</span>
                                </p>
                            </div>
                        </div>
                        <div className="giu_thankyou_message"><p>Thankyou for Your Support</p></div>
                    </div>
                    <div className="col-xxl-4 col-lg-12 col-md-12 col-xs-12">
                        <div className="payment_description_paidto">
                            <h4 className="heading">Paid To: </h4>
                            <div className="giu_connect_img_label">
                                <div className="giu_img_team">
                                    <Image className="img-fluid" src={PayemntDetailLogo} alt="GIU Team Connect LLC"/>
                                    <p> {paymentInfo.paid_to} </p>
                                </div>
                                <div className="giu_query">
                                    <div className="giu_email_phone">
                                        <h4>For any Query</h4>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M0.000521161 15.5386C0.000521161 12.4246 0.000521161 9.31071 0.000521161 6.19678C0.0396082 6.21919 0.0802587 6.24003 0.117782 6.26505C2.80541 8.05993 5.49251 9.85533 8.17962 11.6507C9.39705 12.4643 10.5785 12.4637 11.7996 11.6486C13.1676 10.735 14.5357 9.82093 15.9037 8.90733C17.1962 8.04377 18.4882 7.18021 19.7812 6.31821C19.8328 6.28381 19.8849 6.21293 19.95 6.24472C20.0178 6.27808 19.9792 6.36199 19.9881 6.42348C19.9907 6.44277 19.9881 6.46257 19.9881 6.48185C19.9881 9.37325 19.9886 12.2652 19.9881 15.1566C19.9881 16.2234 19.1803 17.0369 18.1135 17.0374C12.6929 17.0395 7.27175 17.038 1.85116 17.039C1.68074 17.039 1.51449 17.0166 1.35137 16.9697C0.621745 16.7586 0.193351 16.2599 0 15.5391L0.000521161 15.5386Z" fill="#484848"/>
                                                <path d="M0.000976563 3.49938C0.195369 2.77913 0.623242 2.28038 1.35235 2.06879C1.51495 2.02137 1.68172 2 1.85214 2C7.27325 2.00104 12.6944 2 18.1155 2.00156C18.9634 2.00156 19.6758 2.53002 19.9166 3.32583C20.1569 4.11904 19.8723 4.90599 19.1552 5.38546C16.4639 7.18659 13.7711 8.98564 11.0762 10.7816C10.3497 11.2657 9.61326 11.2584 8.88729 10.7743C6.19862 8.98042 3.51256 7.18242 0.823368 5.39015C0.389241 5.10091 0.118759 4.70743 0.000976562 4.20295L0.000976563 3.49938Z" fill="#484848"/>
                                            </svg>
                                            {paymentInfo.email}
                                        </span>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                                <path d="M16.6922 20.9951C15.1128 20.9526 13.6975 20.5762 12.3633 19.9345C7.89733 17.7872 4.54708 14.4787 2.26489 10.0902C1.66192 8.93044 1.24453 7.69618 1.0746 6.39335C0.880068 4.89971 1.02168 3.46943 2.03383 2.26051C2.566 1.62475 3.24723 1.18947 4.07454 1.03966C4.89738 0.890598 5.61662 1.16488 6.10779 1.80884C6.90753 2.85752 7.66106 3.9442 8.37806 5.05102C8.9855 5.98864 8.70675 7.2624 7.81236 7.95258C7.55522 8.15158 7.28988 8.34089 7.0156 8.51604C6.84045 8.62784 6.79871 8.71355 6.93436 8.90212C7.9495 10.322 9.10699 11.6144 10.374 12.8114C11.2267 13.6163 12.1241 14.3676 13.0803 15.0488C13.257 15.1741 13.3516 15.1741 13.4701 14.9825C13.6356 14.7142 13.8242 14.4585 14.0157 14.2074C14.761 13.2302 16.0847 13.0066 17.1029 13.7005C18.0785 14.3654 19.0459 15.0421 20.0059 15.7293C20.9532 16.4083 21.2357 17.5092 20.7527 18.5698C20.0879 20.0284 18.9036 20.7476 17.3548 20.9362C17.1066 20.9667 16.8584 21.0144 16.6929 20.9958L16.6922 20.9951Z" fill="#484848"/>
                                            </svg>
                                            {paymentInfo.phone_number}
                                           
                                        </span>
                                    </div>    
                                    <span className="giu_address">
                                        <h4> Address Details</h4>
                                        <p>  {paymentInfo.street_address}</p>
                                        <p>  {paymentInfo.po_box} </p>
                                        <p>  {paymentInfo.city },{paymentInfo.state } , {paymentInfo.postal_code}    </p>
                                        <p>  {paymentInfo.country}   </p>
                                    </span>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </span>
    )
}

export default PaymentHistoryDetail;