
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import AddPlayerTeam from "./AddPlayerTeam";
import CustomModal from '../../../components/customModal';
import optionico from '../../../assets/images/options.png'
import edit from '../../../assets/images/edit.png'
import deletes from '../../../assets/images/delete.png'
import { Standings } from './Standing';
import { Score } from './Score';
import Matches from './Matches';
import ViewTeam from './viewTeam';
import { ApiGetAuth, Apipost } from '../../../helpers/apiCall';
import DeletePopup from '../../../components/DeletePopup';
import Backbutton from '../../../components/Backbutton';
import Cardloader from '../../Mainloader.js/Cardloader';
import EnrollTeam from './Enrollteam';
import BreadcrumbComponent from "../Breadcrumb/Breadcrumb";
import Venue from "../Venue";
import Referee from "../Referee";
import { NavLink, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import withLoader from "../../../components/PageLoader";
import { generateImage } from "../../../helpers/helperfunctions";
import ImportTeamsModal from "../../../components/ImportTeamsModal";


function TeamComponentWithLoader() {
	const { sportId, gameId, seasonId, groupId } = useParams();
	const {state} = useLocation()
	const [tab, tabActive] = useState(state?.type || "teams");
	const [teamId, setTeamId] = useState(0)
	const [teamList, setTeamList] = useState(null)
	const [tutorialVideos, setTutorialVideos]= useState([])
	const [showModal, setShowModal] = useState(false);
	const [refresh , setRefresh] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteTitle, setDeleteTitle] = useState("")
	const [search, setSearch] = useState('')
	const [gametype, setGameType] = useState('')
	const [values , setValues] = useState([])
	const [counts , setCounts ] = useState({})
	const [modelType, setModelType]= useState('')
	const [componentToRender, setComponentToRender] = useState(<AddPlayerTeam group_id={groupId} sportId={sportId} isUpdate={false} setRefresh={setRefresh} />)

	const navigate = useNavigate()

	const getGroupList = () => {
		ApiGetAuth(`teams/get-Teams-ByGroupId/${groupId}`).then(
			(data) => {
				if (data?.data) {
					setTeamList(data.data.data);
					if(data.data.data.length == 0)setShowModal(true)
					const {matchCount , enrollCount} =data.data
					setCounts({matchCount , enrollCount})
				}
			}
		);
	}
	const getVideoTutorial = () => {
		ApiGetAuth(`dashboard/get-tutorial-videos`).then(
			(data) => {
				if (data?.data) {
					setTutorialVideos(data.data);
					let temp = data.data.filter(item=>item.type == "teams")
					setValues(temp)
				}
			}
		);
	}



	useEffect(() => {
		getGroupList()
		getVideoTutorial()
		Apipost("/common/get-breadcrum", { groupId, seasonId, gameId }, "application/x-www-form-urlencoded", true).then(
			(response) => {
				let result = response.data;
				if (result.status === true) {
					setGameType(result.data.gameType[0].gametype)
				} else {
					console.log(result.data)
				}
			})

	}, [groupId,refresh])

	const closeDeleteModal = () => {
		setTeamId(0)
		setShowDeleteModal(false);
	};
	const openDeleteModal = (id, title,modelType) => {
		setModelType(modelType)
		setTeamId(id)
		setDeleteTitle(title)
		setShowDeleteModal(true);
	};

	const openModal = (value) => {
			if (value) setComponentToRender(<AddPlayerTeam group_id={groupId} sportId={sportId} teamDetails={value.item} isUpdate={true} setShowModal={setShowModal} setRefresh={setRefresh} />)
				else setComponentToRender(<AddPlayerTeam group_id={groupId} sportId={sportId} teamDetails={value.item} isUpdate={false} setShowModal={setShowModal} setRefresh={setRefresh}  />)
			setShowModal(true);

	};
	const closeModal = () => {
			setShowModal(false);
	};

	let filteredTeams = () => {
		let result = teamList
		if (search) {
			return teamList?.filter(item => item.team_name?.toLowerCase()?.startsWith(search.toLowerCase()))
		}
		return result
	};
	function handleNavigate(){
		if(tab != 'teams'){
		 tabActive('teams')
		}else{
			navigate(-1)
		}
	}

function handleChangeTabs(){
	let temp = tutorialVideos.filter(item=>item.type == tab)
	setValues(temp)
}

useEffect(()=>{handleChangeTabs()},[tab])



	return (<>

		<BreadcrumbComponent data={values} />
		<div className="team_list_navs d-flex">
			<div className='team-heading '>
				<div className="team-top-fil">

					<div className='back-btn-dashboard'>
						<Link onClick={handleNavigate}> <span className='back'>
							<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
								<rect width="32" height="32" rx="6" fill="#F05829" />
								<path d="M10.947 17.2243C10.9863 17.3879 11.0873 17.4465 11.1619 17.5219C12.2375 18.6007 13.3139 19.6787 14.3936 20.7535C14.6911 21.0495 14.882 21.3831 14.8572 21.8179C14.8299 22.2903 14.6077 22.6384 14.193 22.8526C13.7776 23.0667 13.3597 23.0475 12.9642 22.7956C12.8391 22.7154 12.7268 22.6111 12.6209 22.5052C11.0039 20.8906 9.38692 19.2752 7.77231 17.6582C6.74484 16.6283 6.74243 15.3699 7.7659 14.344C9.39414 12.7125 11.024 11.0819 12.6562 9.45447C13.257 8.85611 14.0607 8.84969 14.5628 9.4288C15.0023 9.93572 14.9582 10.6672 14.4401 11.1894C13.3532 12.285 12.2592 13.3743 11.1684 14.4667C11.0914 14.5437 11.0184 14.6239 10.9317 14.7162C11.0585 14.806 11.1812 14.7715 11.2935 14.7715C15.4226 14.7739 19.5526 14.7739 23.6817 14.7723C24.1694 14.7723 24.5311 14.9865 24.7638 15.4052C24.9972 15.8263 24.9803 16.2554 24.7245 16.6604C24.467 17.0671 24.0852 17.2291 23.6023 17.2283C19.5133 17.2219 15.4234 17.2251 11.3344 17.2251H10.9462L10.947 17.2243Z" fill="white" />
							</svg>
							<span className='btn-back-to-view'>  Back</span>
						</span></Link>
					</div>
				</div>
			</div>
			<div className="nav_tabs_memmebr_page">
				<NavLink className={tab === 'teams' ? 'isActive' : 'inActive'} onClick={() => tabActive('teams')}>Teams ({teamList?.length})</NavLink>
				<NavLink className={tab === 'enrollment' ? 'isActive' : 'inActive'} onClick={() => tabActive('enrollment')}>Enrollment ({counts.enrollCount})</NavLink>
				<NavLink className={tab === 'games' ? 'isActive' : 'inActive'} onClick={() => tabActive('games')}>Games  ({counts.matchCount})</NavLink>
				<NavLink className={tab === 'score' ? 'isActive' : 'inActive'} onClick={() => tabActive('score')}>Score ({counts.matchCount})</NavLink>
				<NavLink className={tab === 'standings' ? 'isActive' : 'inActive'} onClick={() => tabActive('standings')}>Standing ({teamList?.length})</NavLink>
			</div>

		</div>

		<div className="organizer_events">
			<DeletePopup isOpen={showDeleteModal} onClose={closeDeleteModal} values={teamList} setValues={setTeamList} endpoint={`/teams/delete-Team?teamId=${teamId}&&groupId=${groupId}`} id={teamId} title={deleteTitle} modelType={modelType} />
			<CustomModal showModal={showModal} onClose={closeModal} componentToRender={componentToRender} />
			<div className='row d-flex flex-row'>
				<div className="d-flex flex-row justify-content-between pt-0 pb-3 ">
					<Backbutton />
				</div>
				<div className='grade-content team-list-side'>
					{tab == 'teams' ? <>
						<div className='teams-fixed-properties'>
							<div className='serach-properties'>
								<div className="team-search ">
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
										<path d="M9.19668 0.839844C9.83401 0.92695 10.4665 1.03732 11.0735 1.25833C13.1246 2.00522 14.6622 3.33914 15.6601 5.28522C16.1773 6.29398 16.4576 7.36441 16.5082 8.49868C16.5969 10.4937 16.0277 12.2775 14.8172 13.8611C14.6427 14.0894 14.6435 14.0878 14.8383 14.2901C16.2993 15.8072 17.7596 17.3248 19.2204 18.8418C19.4587 19.0891 19.6664 19.3485 19.671 19.7186C19.6764 20.1641 19.4914 20.502 19.0989 20.7057C18.7002 20.9126 18.3006 20.8823 17.9479 20.5996C17.7463 20.4381 17.5724 20.2407 17.3925 20.0535C16.0053 18.61 14.6178 17.1668 13.2366 15.7177C13.1045 15.5791 13.0115 15.5575 12.8476 15.6684C11.8623 16.3347 10.7716 16.7418 9.59785 16.9076C8.00208 17.1327 6.46204 16.9252 5.00693 16.2132C2.5915 15.0316 1.07391 13.1221 0.518813 10.4954C0.0981614 8.50517 0.422239 6.60399 1.45723 4.84455C2.5658 2.96014 4.18836 1.72605 6.28269 1.10901C6.73796 0.974831 7.20785 0.912612 7.67557 0.839844H9.19695H9.19668ZM8.42084 14.8336C11.6586 14.8425 14.347 12.2502 14.3684 8.94449C14.3895 5.68775 11.8212 2.98124 8.44627 2.97313C5.15707 2.96528 2.5209 5.56954 2.509 8.89065C2.49709 12.2345 5.19792 14.8244 8.42111 14.8336H8.42084Z" fill="#F05829" />
									</svg>
									<input type="text" className="form-control " id="exampleFormControlInput1" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
									<div className="s-icon"></div>
								</div>


							</div>

							<div className=' '>
								<div className='wrap '>
								<ImportTeamsModal setRefresh={setRefresh} />
									<button className="btn btn-dark green orange-color" onClick={() => openModal('')} >
										{/* <img className="plus-icon-craete" src={plus} /> */}
										Create Team</button>
								</div>
							</div>
						</div>
						<div className='row pb-5 mb-3 '>
							{teamList == null ? <Cardloader content={"Teams"} /> : <>
								{teamList?.length ? <>
									{(filteredTeams())?.map((item,i) => {
										return<div className='col-xxl-3 col-xl-4 col-lg-5 col-md-6 col=sm-12' key={i}>

										 <div className='l-card season-mange team-card-details teams-card'>
											<div className='wrap'>
												<div className="parties" onClick={() => { tabActive('teamDetails'); setTeamId(item.id) }} style={{ 'cursor': 'pointer' }}>
												<span>Seed No-{i+1}</span>
													<div className='l-title img_create_team'>
													
														<img src={item?.team_logo || generateImage(item.team_name)} width="40px" />
														{/* <Link to={`/organizer/${sportId}/teams/view-team`} state={{ id: item.id, title: "Title" }} title="View" > */}
														<div className='card-content' >
															<h6 >{item.team_name} </h6>
															
															<div className="text-group">
																<p><strong>Age Group: </strong>{item.age_group}</p>
																<p><strong>Grade: </strong>{item.grade_name}</p>
															</div>

														</div>
													</div>
													<div className="text-center">

													</div>


													<div className='groups-count team-list team-views-bottom'>
														<div className='group total-groups me-2'>
															<p>Coach :</p>
															<strong>{item.members?.filter(item => item.role_id == 3)?.length}</strong>
														</div>
														<div className='group total-groups me-2'>
															<p>Players :</p>
															<strong>{item.members?.filter(item => item.role_id == 4)?.length}</strong>
														</div>
														<div className='group total-groups me-2'>
															<p>Team Mom :</p>
															<strong>{item.members?.filter(item => item.role_id == 9)?.length}</strong>
														</div>
													</div>
												</div>
											</div>
											<div className="dropdown acts team_component">
												<button className=" dropdown-toggle actions" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
													<img className='dots team_img' src={optionico} />
												</button>
												<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
													<li><a className="dropdown-item" onClick={() => openModal({ item: item })} ><img src={edit} className="team_img" />Edit</a></li>
													<li onClick={() => openDeleteModal(item.id, item.team_name, 'Archive')}><a className="dropdown-item" ><img src='https://cdn.icon-icons.com/icons2/1744/PNG/512/3643772-archive-archives-document-folder-open_113445.png' alt='Archive icon' />Archive</a></li>
													<li onClick={() => openDeleteModal(item.id, item.team_name, 'Delete')}><a className="dropdown-item" ><img src={deletes} className="team_img" />Delete</a></li>
												</ul>
											</div>
										</div>
										</div>


									})}
								</> : <> <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col=sm-12'><div className='l-card create-league'>
									<div className='wrap'>
										<span> No Data Found</span>
									</div>

								</div> </div></>
								}
							</>}
						</div>
					</> : <>
						{tab === 'standings' ?
							<>
								<Standings groupid={groupId} teams={teamList} />
							</> : <>
								{tab === 'score' ?
									<>
										<Score groupid={groupId} />
									</> :
									<>
										{tab === 'games' ?
											<>
												<Matches teams={teamList} sportsid={sportId} groupid={groupId} setActive={tabActive} isLeague={gametype == "League"} /></> : <>
												{
													tab === 'teamDetails' ?
														<>
															{/* <BreadcrumbComponent /> */}
															<ViewTeam id={state?.teamId|| teamId} price={0} tabActive={tabActive} /></> : <>
															{
																tab === 'enrollment' ?
																	<EnrollTeam groupid={groupId} getGroupList={getGroupList} AddedTeams={teamList} price={0} tabActive={tabActive} setTeamId={setTeamId} /> : <>
																		{tab === 'venues' ? <><Venue />
																		</> : <>
																			{tab === "members" ? <Referee /> : <>
																			</>}
																		</>
																		}
																	</>
															}
														</>}

											</>}
									</>
								}
							</>
						}
					</>}


				</div>
			</div>
		</div>
	</>);
}

const TeamComponent = withLoader(TeamComponentWithLoader);


export default TeamComponent;