import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Paymentsuccess } from '../top-nav/paymentsuccess';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import '../../assets/css/enrollmentpayment.css';
import { Apipost } from '../../helpers/apiCall';
import { GameType } from './GameType';

export const PaymentSideNav = ({ show, setShow, season_id = 0, price = null, ...props }) => {
    const selector = useSelector(state => state);
    const [total, setTotal] = useState(0);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [data, setData] = useState([]);
    const [promoCode, setPromoCode] = useState({});
    const [promoError, setPromoError] = useState({});
    const [promoSuccess, setPromoSuccess] = useState({});
    const [discountAmount, setDiscountAmount] = useState({});

    useEffect(() => {
        if (selector.PAYMENTDETAIL) {
            const { rows } = selector.PAYMENTDETAIL;
            setData(rows || []);
            let temp = 0;
            rows?.forEach(item => {
                const { grandTotal } = calculateChargeDetails(item, selector.PAYMENTDETAIL.charges);
                temp += parseFloat(grandTotal);
            });
            setTotal(temp.toFixed(2));
            resetPromoState();
        }
    }, [selector.PAYMENTDETAIL]);

    const resetPromoState = () => {
        setPromoCode({});
        setDiscountAmount({});
        setPromoError({});
        setPromoSuccess({});
    };

    const calculateChargeDetails = (item, charges) => {
        // Ensure all numeric inputs are parsed as numbers
        const teamCount = parseFloat(item.teamCount) || 0;
        const teamCharge = teamCount * parseFloat(charges.team_charge || 0);
        const discount = parseFloat(discountAmount[item.season_id]?.discountAmount || 0);
        const discountedCharge = teamCharge - discount;
    
        // Calculate tax and transaction charges
        const taxRate = (parseFloat(charges.transaction_charges || 0) + parseFloat(charges.tax || 0)) / 100;
        const tax = parseFloat((discountedCharge * taxRate).toFixed(2));
        const grandTotal = parseFloat((discountedCharge + tax).toFixed(2));
    
        return {
            teamCharge: teamCharge.toFixed(2),
            tax: tax.toFixed(2),
            grandTotal: grandTotal.toFixed(2),
        };
    };
    

    const applyPromoCode = (id, total_amount, game_id) => {
        if (promoSuccess[id] && discountAmount[id]?.coupon_code === promoCode[id]) {
            return;
        }

        Apipost('/payment/apply-coupon', { promo: promoCode[id], total_amount, game_id }, 'application/x-www-form-urlencoded', true)
            .then(response => {
                setPromoError(prev => ({ ...prev, [id]: '' }));
                setPromoSuccess(prev => ({ ...prev, [id]: response.data.message }));
                setDiscountAmount(prev => ({ ...prev, [id]: response.data.data }));
            })
            .catch(err => {
                setPromoSuccess(prev => ({ ...prev, [id]: '' }));
                setPromoError(prev => ({ ...prev, [id]: err.response?.data.message || 'Failed to apply promo code' }));
                setDiscountAmount(prev => {
                    const newState = { ...prev };
                    delete newState[id];
                    return newState;
                });
            });
    };

    return (
        <>
            {props?.showDue && <p>Due: ${total}</p>}
            <div className="PaymentSideNav">
                {price === null ? (
                    <Button variant="primary paynowbtn" onClick={total > 0 ? handleShow:()=>{}} className="ms-2" disabled={total === 0}>
                        Pay Now
                    </Button>
                ) : (
                    <Link onClick={price > 0 ? handleShow : () => { }} disabled={total === 0} >$ {price}</Link>
                )}
                <Offcanvas show={show} onHide={handleClose} {...props} className="sidenav_offcanvas PaymentSideNav-offcanvas">
                    <div className="create_game px-3">
                        <div className="create-match-wrap season-popup">
                            <Offcanvas.Body>
                                <Offcanvas.Header closeButton className="offcanvas-header">
                                    <h5 className="text-left">Payment</h5>
                                </Offcanvas.Header>

                                <div className="payment-division">
                                    {data === null ? (
                                        <>No Data Found</>
                                    ) : (
                                        (season_id === 0 ? data : data.filter(item => item.season_id === season_id)).map((item, i) => {
                                            const { teamCharge, tax, grandTotal } = calculateChargeDetails(item, selector.PAYMENTDETAIL.charges);
                                              console.log(tax , "tax")
                                            return (
                                                <div key={i} className="payment-enroll-details">
                                                    <div className="payment-details-view">
                                                        <div className="payment-enroll-details-legue">
                                                            <GameType game_type_id={item.game_type_id} />
                                                            <div className="date ms-3 mt-1 date-card pb-2">
                                                                <p>
                                                                    <span>
                                                                        <img
                                                                            src={selector.SPORTSLIST.find(sport => sport.id === item.sport_id)?.icon}
                                                                            width={30}
                                                                        />
                                                                    </span>{' '}
                                                                    {item.game_title}
                                                                </p>
                                                                <p><strong>{item.season_name}</strong></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="payment-method-list">
                                                        <div className="top-payment">
                                                            <div>
                                                                <p>{item.teamCount || 0} Teams x $ {selector.PAYMENTDETAIL.charges.team_charge} /Team</p>
                                                            </div>
                                                            <div>
                                                                <Link to="#">
                                                                    <span className="team-btn">$ {teamCharge}</span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        {discountAmount[item.season_id] && (
                                                            <div className="top-payment">
                                                                <div>{discountAmount[item.season_id].coupon_description}</div>
                                                                <div>$ - {discountAmount[item.season_id].discountAmount.toFixed(2)}</div>
                                                            </div>
                                                        )}
                                                        <div className="top-payment">
                                                            <div>
                                                                <p>Additional ({selector.PAYMENTDETAIL.charges.tax}% Tax + {selector.PAYMENTDETAIL.charges.transaction_charges}% Transaction Charges)</p>
                                                            </div>
                                                            <div>
                                                                <Link to="#">
                                                                    <span className="team-btn">$ {tax}</span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="top-payment">
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    placeholder="Enter promo code"
                                                                    value={promoCode[item.season_id] || ''}
                                                                    onChange={(e) => setPromoCode(prev => ({ ...prev, [item.season_id]: e.target.value }))}
                                                                />
                                                            </div>
                                                            <div>
                                                                <button
                                                                    onClick={() => applyPromoCode(item.season_id, parseFloat(teamCharge), item.game_id)}
                                                                    className="btn btn-primary w-20 my-2"
                                                                >
                                                                    Apply Promo Code
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {promoError[item.season_id] && <div className="text text-danger">{promoError[item.season_id]}</div>}
                                                        {promoSuccess[item.season_id] && <div className="text text-success">{promoSuccess[item.season_id]}</div>}
                                                        <hr />
                                                        <div className="bottom-payment ">
                                                            <div className="pay_button ">
                                                                <Paymentsuccess
                                                                    placement="end"
                                                                    value={item}
                                                                    price={grandTotal}
                                                                    prevClose={handleClose}
                                                                    discountAmount={discountAmount}
                                                                    promoError={promoError}
                                                                    additionalCharges={tax}
                                                                    promoSuccess={promoSuccess}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    )}
                                </div>
                            </Offcanvas.Body>
                        </div>
                    </div>
                </Offcanvas>
            </div>
        </>
    );
};
